.invitation-page {
  display: flex;
  flex-direction: column;
  .section-left {
    background-color: #15abe6;
    color: #fff;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .section-main {
    min-height: 100%;
    flex-grow: 1;
    overflow: auto;
    padding: 16px;
    .section-main-container {
      width: 100%;
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .stella-logo {
        width: 100px;
      }
    }
  }
}

@media (min-width: 900px) {
  .invitation-page {
    flex-direction: row;
    .section-left {
      width: 400px;
      .promotion {
        width: 280px;
      }
    }
  }
}
